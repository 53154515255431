<template>
  <div
    class="carousel_container"
    @touchstart="handle_touch_start"
    @touchend="handle_touch_end"
  >
    <div class="carousel" :style="carousel_style">
      <img
        v-for="(image, index) in images"
        :key="index"
        :src="require(`@/assets/photos/${image}`)"
        :alt="`Image ${index + 1}`"
      />
    </div>
  </div>

	<div class="carousel_index">
	<p v-for="(image, index) in images"
     :key="index"
     :class="{ index_selected : current_image_index === index }"></p>
	</div>
</template>

<script setup>
import { computed, defineProps, ref } from 'vue'
const props = defineProps({
	images: Object,
})

const touch_start_x = ref(0)
const touch_end_x = ref(0)
const current_image_index = ref(0)
const carousel_style = computed(() => {
	return {
		transform: `translateX(-${current_image_index.value * 100}%)`
	};
})

function handle_touch_start(event) {
  touch_start_x.value = event.touches[0].clientX;
}

function handle_touch_end(event) {
  touch_end_x.value = event.changedTouches[0].clientX;
  handle_swipe();
}

function handle_swipe() {
  const threshold = 50; // Minimum distance of swipe to trigger
  const swipe_distance = touch_start_x.value - touch_end_x.value;
  if (swipe_distance > threshold && current_image_index.value < (props.images.length-1)) {
    // Left swipe
    next_image();
  } else if (swipe_distance < -threshold && current_image_index.value > 0) {
    // Right swipe
    prev_image();
  }
}

function next_image() {
  current_image_index.value = (current_image_index.value + 1) % props.images.length;
}

function prev_image() {
  current_image_index.value =
    (current_image_index.value + props.images.length - 1) % props.images.length;
}
</script>

<style>
.carousel_index {
  display: flex;
  border-left: var(--border-width) solid;
}

.carousel_index p {
  margin: unset;
  padding: 2px;
  width: 100%;
  text-align: center;
  border-right: var(--border-width) solid;
  border-bottom: var(--border-width) solid;
}

.index_selected {
  background-color: var(--color-O);
}

.carousel_container {
  position: relative;
  overflow: hidden;
  border: var(--border-width) solid; /* Fixed black boundary */
  touch-action: pan-y; /* Enable vertical screen scrolling, prevent horizontal */
}

.carousel {
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.carousel img {
  max-width: 100%;
  display: block; /* Remove any extra space below images */
}
</style>
