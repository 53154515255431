<template>
  <button @click.stop='no_action' class='wa_inquiry'><a :href='`mailto:inquiry@western-acoustics.com?Subject=${props.subject}`'>Inquiry &rarr;</a></button>
</template>
<script setup>
import { defineProps } from 'vue'
const props = defineProps({
  subject: String,
})

function no_action() {
  // Used as a filler to take advantage of vue's anti-propagation logic
  // This prevents product panel from expanding after selecting inquire
}
</script>
