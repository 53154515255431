<template>
<div
  class='gallery'
  @mousedown="handle_mouse_down"
  @mousemove="handle_mouse_move"
  @mouseup="handle_mouse_up"
  @mouseleave="handle_mouse_leave"
  ref='gallery'
>
  <div class='row'>
    <img
      v-for='photo in first_half_photos'
      :key='photo.id'
      :src='photo.url'
      :alt='photo.name'
      class='photo_item'
      loading='lazy'
    />
  </div>
  <div class='row'>
    <img
      v-for='photo in second_half_photos'
      :key='photo.id'
      :src='photo.url'
      :alt='photo.name'
      class='photo_item'
      loading='lazy'
    />
  </div>
</div>
<div class='scrollbar'>
</div>
</template>
<script setup>
import { ref } from 'vue'

const gallery = ref(null)
const photo_context = require.context('@/assets/photos/gallery', false, /\.(png|jpe?g|gif)$/);
const photos = photo_context.keys().map(photo_path => ({
  id: photo_path,
  url: photo_context(photo_path),
  name: photo_path.split('/').pop()
}))

const first_half_photos = photos.slice(0, photos.length / 2)
const second_half_photos = photos.slice(photos.length / 2)
let is_mouse_down = ref(false)
let start_x = ref(0)
let scroll_left = ref(0)

function handle_mouse_down(e) {
  is_mouse_down.value = true;
  gallery.value.style.cursor = 'grabbing';
  start_x.value = e.pageX - gallery.value.offsetLeft;
  scroll_left.value = gallery.value.scrollLeft;
}

function handle_mouse_leave() {
  gallery.value.style.cursor = 'grab';
  is_mouse_down.value = false;
}

function handle_mouse_up() {
  gallery.value.style.cursor = 'grab';
  is_mouse_down.value = false;
}

function handle_mouse_move(e) {
  if (!is_mouse_down.value) return;
  e.preventDefault();
  const x = e.pageX - gallery.value.offsetLeft;
  const walk = x - start_x.value;
  gallery.value.scrollLeft = scroll_left.value - walk;
}
</script>
<style>
@import '@/assets/css/wa.css';
:root {
  --photo-height: 33.33vw;
}

.gallery {
  display: flex;
	box-sizing: border-box;
  flex-direction: column;
  height: calc(var(--photo-height) * 2);
  overflow-x: hidden;
  overflow-y: hidden;
  user-select: none;
  cursor: grab;
	border-left: var(--border-width) solid black;
	border-right: var(--border-width) solid black;
}

.gallery img {
	box-sizing: border-box;
  border-right: var(--border-width) solid black;
  border-top: var(--border-width) solid black;
}

.row {
  display: flex;
  flex-direction: row;
}

.photo_item {
  height: var(--photo-height);
  width: var(--photo-height);
  flex: none;
}

@media (min-width: 880px) {
  :root {
    --photo-height: 310px;
  }

	.gallery {
		border-left: unset;
		border-right: unset;
	}

}
</style>
