<template>
<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="path-1-inside-1_1_450" fill="white">
  <path d="M0 0H44V44H0V0Z"/>
  </mask>
  <path d="M0 0H44V44H0V0Z" fill="#E38E35"/>
  <path d="M0 0V-1.5H-1.5V0H0ZM0 1.5H44V-1.5H0V1.5ZM1.5 44V0H-1.5V44H1.5Z" fill="#0D120C" mask="url(#path-1-inside-1_1_450)"/>
  <path d="M22 29.7L22 14.52" stroke="#0D120C" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="bevel"/>
  <path d="M19.7998 16.28L21.9998 14.08" stroke="#0D120C" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="bevel"/>
  <path d="M24.2002 16.28L22.0002 14.08" stroke="#0D120C" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="bevel"/>
</svg>
</template>
