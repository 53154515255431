<!-- Product panel component -->
<template>
<div ref='wa_pp'>
  <div ref='inner_content' class='inner_content' @click='wa_pp_current_click_function'>
    <img ref='desktop_thumbnail' id='desktop_thumbnail' :src='imgs[0]' />
    <div id='carousel'>
      <ImageCarousel
        :images='[
            `${props.sku}/thumbnail.jpeg`,
            `${props.sku}/img_1.jpeg`,
            `${props.sku}/img_2.jpeg`,
            `${props.sku}/img_3.jpeg`]'/>
    </div>
    <div class='wa_content'>
      <slot name='header'/>
      <div id='tab_bar'>
        <button class='tab_button'
                :class="{ leftmost_tab_selected: current_tab === 'tab1' }"
                @click="current_tab = 'tab1'">
          Description
        </button>
        <button class='tab_button'
                :class="{ tab_selected: current_tab === 'tab2' }"
                @click="current_tab = 'tab2'">
          Specs
        </button>
        <button class='tab_button'
                :class="{ tab_selected: current_tab === 'tab3' }"
                @click="current_tab = 'tab3'">
          Frequency Response
        </button>
        <button id='tab_bar_filler' class='tab_button'></button>
      </div>
      <div id='tab_divs'>
        <div v-show="current_tab === 'tab1'">
          <slot name='description'/>
        </div>
        <div v-show="current_tab === 'tab2'">
          <ul class='wa_ul'>
            <li v-for='spec in specs' :key='spec.id'>{{spec}}</li>
          </ul>
        </div>
        <div v-show="current_tab === 'tab3'">
          <slot name='frg_graph'>
          </slot>
        </div>
      </div>
    </div>
  </div>
  <!-- for desktops -->
  <div class='expanded_content' v-if='is_expanded'>
    <img class='caption_imgs'
         :src='imgs[1]'
         @click='swap_thumbnail(imgs[1])'/>
    <img class='caption_imgs'
         :src='imgs[2]'
         @click='swap_thumbnail(imgs[2])'/>
    <img class='caption_imgs'
         :src='imgs[3]'
         @click='swap_thumbnail(imgs[3])'/>
    <div class='specs'>
      <h4 class='wa_label'>Specifications</h4>
      <ul class='wa_ul'>
        <li v-for='spec in props.all_specs[props.sku]' :key='spec.id'>{{spec}}</li>
      </ul>
    </div>
    <img id='frg_graph' :src='require(`@/assets/photos/${props.sku}/frg.png`)' />
    <UpwardArrowButton @click='wa_pp_close' id='close_expand_btn'/>
  </div>
</div>
</template>

<script setup>
import { computed, onMounted, defineProps, ref } from 'vue'
import UpwardArrowButton from './UpwardArrowButton.vue'
import ImageCarousel from './ImageCarousel.vue'

// Args for product panel components, passed from parent (App.vue)
const props = defineProps({
  sku: String,
  all_specs: Object,
})

const imgs = [
  require(`@/assets/photos/${props.sku}/thumbnail.jpeg`),
  require(`@/assets/photos/${props.sku}/img_1.jpeg`),
  require(`@/assets/photos/${props.sku}/img_2.jpeg`),
  require(`@/assets/photos/${props.sku}/img_3.jpeg`),
]

/* For desktop settings different from mobile */
const desktop_media_query = window.matchMedia('(min-width: 880px)')

// Ref vars
const is_expanded = ref(false)
const is_swapped = ref(false)
const wa_pp = ref(null)
const inner_content = ref(null)
const current_tab = ref('tab1')
const desktop_thumbnail = ref(null)
const specs = computed(() => { // For all_specs is set in an async function in App.vue
  let all_specs = []
  if (props.all_specs) {
    all_specs = props.all_specs[props.sku]
  }
  return all_specs
})

function swap_thumbnail(src_to_swap) {
  if (!is_swapped.value) {
    desktop_thumbnail.value.src = src_to_swap
  } else {
    const thumbnail_src_fname = desktop_thumbnail.value.src.split('/').pop()
    const swap_src_fname = src_to_swap.split('/').pop()

    if (thumbnail_src_fname === swap_src_fname) {
      desktop_thumbnail.value.src = imgs[0]
    } else { // Case where is_swapped == True but we click a different caption image
      desktop_thumbnail.value.src = src_to_swap
      is_swapped.value = !is_swapped.value
    }
  }
  is_swapped.value = !is_swapped.value
}

function wa_pp_expand() {
  is_expanded.value = true
  wa_pp_current_click_function.value = null
  wa_pp.value.classList.add('wa_pp_expanded')
  inner_content.value.classList.remove('hover_highlight')
}

function wa_pp_close() {
  is_expanded.value = false
  wa_pp_current_click_function.value = wa_pp_expand
  wa_pp.value.classList.remove('wa_pp_expanded')
  inner_content.value.classList.add('hover_highlight')
}

const wa_pp_current_click_function = ref(null);

function desktop_media(e) {
  if (e.matches) {
    wa_pp_current_click_function.value = wa_pp_expand
    inner_content.value.classList.add('hover_highlight')
  } else { // This shouldnt ever really be triggered unless a screen gets dynamically smaller
    wa_pp_current_click_function.value = null
    inner_content.value.classList.remove('hover_highlight')
  }
}

onMounted(() => {
  // Triggers on screens wider than 880px or whatever is specified above
  // Needs to be added after mounting because we modify the inner_content dom element
  desktop_media(desktop_media_query)
  desktop_media_query.addListener(desktop_media)
})

</script>

<style>
@import '@/assets/css/wa.css';

.hover_highlight:hover {
  background-color: var(--color-O);
}

.inner_content {
  height: var(--wa-pp-height);
  display: flex;
  flex-direction: column;
}

#tab_bar {
  display: flex;
}

#tab_bar_filler {
  width: 100%;
}

.tab_button {
  border-top: unset;
  border-bottom: var(--border-width) solid black;
  border-left: unset;
  border-right: unset;
  color: var(--color-D);
  background: var(--color-L);
  text-wrap: nowrap;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.tab_selected {
  border-top: var(--border-width) solid black;
  border-bottom: unset;
  border-right: var(--border-width) solid black;
  border-left: var(--border-width) solid black;
}

.leftmost_tab_selected {
  border-bottom: unset;
  border-right: var(--border-width) solid black;
  border-top: var(--border-width) solid black;
  border-left: unset;
}

.expanded_content {
  position: relative;
  height: calc(var(--wa-pp-expanded-height) - var(--wa-pp-height));
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.caption_imgs {
  height: calc(var(--wa-pp-height)/3 - (var(--border-width)*2)/3);
  border-right: var(--border-width) solid black;
  border-bottom: var(--border-width) solid black;
  border-top: var(--border-width) solid black;
  cursor: pointer;
}

.expanded_content p,
.expanded_content ul,
.expanded_content button {
  margin-left: 20px;
}

#desktop_thumbnail {
  display: none;
}

#frg_graph {
  width: 100%;
  height: auto;
}

#close_expand_btn {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}

/* desktop styles */
@media (min-width: 880px) {
  .inner_content {
    flex-direction: row;
  }

  .inner_content img {
    border-right: var(--border-width) solid black;
    border-left: unset;
    border-top: unset;
    border-bottom: unset;
    height: 100%;
  }

  #tab_bar {
    display: none;
  }

  #desktop_thumbnail {
    display: block;
  }

  #carousel {
    display: none;
  }

  #frg_graph {
    width: 440px;
    margin-left: calc(var(--margin) * 2);
    height: fit-content; /* It should be auto, but it causes img stretch. Not sure why */
  }
}

</style>
