<!-- home page for western-acoustics.com -->
<template>
  <div class='wa_page'>
    <header>
      <div class="navbar">
          <LogoSvg />
          <a v-for='section in sections' :href="`#${section}`" :key='section.id'>
						<button class='wa_btn'
										:class='{ "highlight": section === active_section }'>
							{{ section }}
						</button>
					</a>
					<br>
      </div>
    </header>
    <div class='wa_body'>
      <div id='banner'>
        <LogoSvg id='banner_logo'/>
        <div>
          <p>High fidelity active loudspeaker systems</p>
        </div>
      </div>
      <div id='Products'>
        <ProductPanel class='wa_pp' sku="wa_t2" :all_specs="all_specs">
          <template v-slot:header>
            <div class='wa_pp_header'>
              <h1>Type 2</h1>
              <h4 class='wa_price_caption'>$5000.00/pair</h4>
              <InquireButton subject='Type%202%20Inquiry'/>
            </div>
          </template>
          <template v-slot:description>
            <p>
              2-way active bookshelf speaker. Utilizes the now famous Purifi PTT6.5X04-NFA-01 ultra low distortion 6.5" woofer, which allows for exceptional bass from a small package. Custom horn machined from solid wood. Side mounted passive radiator. Price is for a pair, each of which is powered by a Hypex 350-watt (RMS) DSP plate amplifier. Multiple wood species, finishes, and grille cloth options available.
            </p>
          </template>
          <template v-slot:frg_graph>
            <img id='frg_graph' :src='require(`@/assets/photos/wa_t2/frg.png`)' />
          </template>
        </ProductPanel>
        <ProductPanel class='wa_pp' sku="wa_t1" :all_specs="all_specs">
          <template v-slot:header>
            <div class='wa_pp_header'>
              <h1>Type 1</h1>
              <h4 class='wa_price_caption'>price upon request</h4>
              <InquireButton subject='Type%201%20Inquiry'/>
            </div>
          </template>
          <template v-slot:description>
            <p>
              Large high end two-way home loudspeaker system. We utilize dual low-excursion 12” Japanese woofers from Fostex that reproduce highly transient music like Jazz with elegance, while still having the low end power for modern electronic and rap music. Custom horn designed by Joseph Crowe. Multiple wood species, finishes, and grille cloth options available.
            </p>
          </template>
          <template v-slot:frg_graph>
            <p>Under current devleopment</p>
          </template>
        </ProductPanel>
      </div>
      <div id='Commission'>
        <div class='wa_content'>
          <h1>Commission</h1>
          <p>We occassionally accept commissions when we have the time and it feels right. If you are interested in a commission, reach out at <a href='mailto:inquiry@western-acoustics.com?Subject=Commission'>inquiry@western-acoustics.com</a> and we will review your request.</p>
        </div>
      </div>
      <div id='Gallery'>
        <PhotoGallery />
      </div>
      <div id='About'>
        <div class='wa_content'>
          <h1>About</h1>
          <p>We are a small loudspeaker design and manufacturing shop with a focus on actively powered high fidelity systems. Our office and workshop is located in the Dogpatch district of San Francisco.</p>
          <h3>Our Design</h3>
          <p>Our goal is to create unique loudspeaker systems that are simple to use with exceptional audio quality. Actively powered systems allow us to use modern audio technology and reduce the number of components needed by the listener. This means the user does not need to source a separate amplifier, and they can enjoy the benefits of modern advances like Digital Signal Processing.</p>
					<h3>Contact</h3>
					<p>For orders or questions, contact us at <a href='mailto:inquiry@western-acoustics.com?Subject=Inquiry'>inquiry@western-acoustics.com</a></p>
        </div>
      </div>
      <div id='Careers'>
        <div class='wa_content'>
          <h1>Careers</h1>
          <p>We're looking to hire intelligent, high energy, self-driven individuals who are willing to work extremely hard to help build a world class audio and design company.</p>
          <p>If you think you are a good fit for one of the positions listed below, <a href='mailto:inquiry@western-acoustics.com?Subject=WA Careers'>send us an email</a> with your resume.</p>
          <h3>Chief Operations Officer</h3>
          <p>This individual will be responsible for the day-to-day management of production processes, logistics, event planning, and coordination with third parties. This role requires a highly organized, detail-oriented individual with strong project management skills and the ability to manage multiple projects simultaneously.</p>
        </div>
      </div>
      <div class='footer'>
        <div class='copyright'>
          <p>© Copyright 2024 Western Acoustics, Inc. All Rights Reserved.</p>
        </div>
        <div class='social-icons'>
          <a class='social-icon' target='_blank' href='https://www.instagram.com/westernacoustics/'>
            <InstagramLogoSvg/>
          </a>
          <a class='social-icon' target='_blank' href='https://twitter.com/western_acoustc'>
            <XLogoSvg class='social-icon' />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import ProductPanel from './components/ProductPanel.vue'
//import ProductPanelExpanded from './components/ProductPanelExpanded.vue'
import PhotoGallery from './components/PhotoGallery.vue'
import LogoSvg from './components/LogoSvg.vue'
import InstagramLogoSvg from './components/InstagramLogoSvg.vue'
import XLogoSvg from './components/XLogoSvg.vue'
import InquireButton from './components/InquireButton.vue'

const all_specs = ref(null)
const active_section = ref(null)
const sections = [
	'Products',
	'Commission',
	'Gallery',
	'About',
  'Careers',
]

async function fetch_specs() {
  const url = '/api/fetch_all_spec_strings'
  try {
    const response = await fetch(url)

    if (!response.ok) {
      throw new Error('Network response was not ok')
    }

    all_specs.value = await response.json()
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error)
  }
}

function check_active_section() {
	const threshold = 5; // bottom threshold in pixels; adjust as needed
	const is_at_bottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - threshold;
	if (is_at_bottom) {
		active_section.value = sections[sections.length-1]
		return
	}

  for (let i = 0; i < sections.length; i++) {
    const section = sections[i];
    const element = document.getElementById(section);
    const top = element.offsetTop;
    const bottom = top + element.offsetHeight;
    const scrollPosition = window.scrollY + window.innerHeight / 4;
    if (scrollPosition >= top && scrollPosition <= bottom) {
      active_section.value = section
      return
    }
  }
  active_section.value = null
}

onMounted(() => {
    window.addEventListener('scroll', check_active_section);
		check_active_section()
})

fetch_specs()
</script>

<style>
@import './assets/css/wa.css';
.wa_page {
  display: flex;
  flex-direction: row;
}

#banner {
  background-color: var(--color-L); /* L color from your palette */
  display: flex;
  align-items: center; /* align vertical */
  justify-content: space-between;    /* pushes copyright and social icons apart */
  text-align: right;
  margin-left: var(--page-margin);
  margin-right: var(--page-margin);
  margin-top: 0px;
  margin-bottom: var(--page-margin);
}

#banner p {
  font-family: "FranklinGothicATF Medium";
  margin: unset;
  font-size: small;
}

#banner_logo {
  margin-right: 50px;
}

#Commission {
  height: fit-content;
  margin: 0px;
  background-color: var(--color-G);
  color: var(--color-L);
  display: flex;
  align-items: center;
  padding: calc(var(--page-margin) * 2);
}

#Commission a {
  color: var(--color-L);
}

#About {
  margin: 0px;
  background-color: var(--color-B);
  color: var(--color-L);
  display: flex;
  align-items: center;
	padding: calc(var(--page-margin) * 2);
}

#About a {
	color: var(--color-L);
}

#Careers {
  margin: 0px;
  background-color: var(--color-B);
  color: var(--color-L);
  display: flex;
  align-items: center;
	padding: calc(var(--page-margin) * 2);
}

#Careers a {
	color: var(--color-L);
}


.footer {
  display: flex;
  justify-content: space-between;    /* pushes copyright and social icons apart */
  align-items: center;               /* vertically aligns items if footer height is greater than its content */
  padding: 10px 20px;                /* some padding for better appearance */
  background-color: var(--color-L);  /* any color of your choice for the footer */
}

.copyright {
  margin: 0;   /* removing any default margins */
}

.social-icons {
  display: flex;   /* makes social icons display inline */
  gap: 10px;       /* space between icons */
}

.social-icon {
  text-decoration: none;   /* remove underline from links */
}

.social-icon:hover {
  color: #ddd;             /* darken color on hover */
}

@media (min-width: 880px) {
  #banner {
    height: 90px;
    margin: var(--margin);
  }

  #banner p {
    font-size: medium;
  }

  #banner_logo {
    display: none;
  }

  #Commission {
    color: var(--color-D);
    height: 310px;
    border-top: var(--border-width) solid;
    background-color: var(--color-L);
  }

  #Commission a {
    color: var(--color-D);
  }

  #About {
    height: 410px;
    border-top: var(--border-width) solid;
    background-color: var(--color-L);
    color: var(--color-D);
  }

	#About a {
		color: var(--color-D);
	}

  #Careers {
    height: 410px;
    border-top: var(--border-width) solid;
    background-color: var(--color-L);
    color: var(--color-D);
  }

	#Careers a {
		color: var(--color-D);
	}

  .footer {
    border-top: var(--border-width) solid;
  }
}
</style>
